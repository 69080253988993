// Import BS.
@import '../../node_modules/bootstrap/scss/bootstrap';

@import './variables';

.link-unstyled,
.link-unstyled:link,
.link-unstyled:hover {
  color: inherit;
  text-decoration: inherit;
}

.header-logo {
  background: url(https://d21c62yfvjssc.cloudfront.net/assets/ets-website/logo-transparent.png);
  height: 8rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.heavy-font {
  font-weight: 500;
}
